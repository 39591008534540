
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileTypeDetail } from '@/types/product'

@Component({
  name: 'FileTypeAdd'
})
export default class extends Vue {
  private title = '新增文件类型'
  private info: FileTypeDetail = {
    typeName: '',
    sdescribe: ''
  }

  private rules = {
    typeName: [
      { required: true, message: '请输入文件类型名称', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  get typeId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.typeId) {
      this.title = '编辑文件类型'
      this.getDetail()
    }
  }

  // 文件类型详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectProductFileTypeByTypeId, {
      typeId: this.typeId
    }).then((res) => {
      this.info = res
    })
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.addFileType()
      }
    })
  }

  // 用户详情
  addFileType () {
    this.submitShow = true
    const url = this.typeId
      ? this.$apis.product.updateProductFileType
      : this.$apis.product.insertProductFileType
    this.$axios.post(url, this.info).then(() => {
      this.submitShow = false
      this.$message.success('保存成功')
      this.$router.push({ path: '/productList/baseSet/fileType' })
    }).finally(() => {
      this.submitShow = false
    })
  }
}
