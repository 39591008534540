import { render, staticRenderFns } from "./AddFileType.vue?vue&type=template&id=40409140&scoped=true"
import script from "./AddFileType.vue?vue&type=script&lang=ts"
export * from "./AddFileType.vue?vue&type=script&lang=ts"
import style0 from "./AddFileType.vue?vue&type=style&index=0&id=40409140&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40409140",
  null
  
)

export default component.exports